import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import {
  Table, TableHead, TableBody, TableCell, TableRow,
} from '@material-ui/core';
import Banner from '../layout/Banner';

const styles = {
  heading: {
    width: '20%',
  },
  action: {
    width: '50px',
    textAlign: 'right',
  },
  lightGrey: {
    color: '#9B9B9B',
  },
};

class MessageDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: {},
    };
  }

  componentDidMount = async () => {
    const { appUrl, id } = this.props.match.params;
    const appResponse = await fetch(`/api/app/${appUrl}`);
    const app = await appResponse.json();
    const topicResponse = await fetch(`/api/apps/${appUrl}/topics`);
    const topicData = await topicResponse.json();
    const request = await fetch(`/api/apps/${appUrl}/message/${id}`);
    const { message } = await request.json();
    this.setState({
      app,
      message,
      topics: topicData.topics,
      loading: false,
      appUrl,
    });
  }

  findTopic = (topicId) => {
    const { topics } = this.state;
    if (!topicId) return 'No Topic';
    const topic = _.find(topics, { id: topicId });
    return topic.display_name;
  };

  render() {
    const { classes } = this.props;
    const { appUrl } = this.props.match.params;
    const { message, loading, app } = this.state;
    const topicName = this.findTopic(message.topic_id);
    if (loading) return null;
    return (
      <div>
        <ul className="breadcrumbs">
          <li>
            <Link to={`/push-notifications/${appUrl}/messages`}>
              {app.name}
            </Link>
          </li>
          <li>
            <Link to={`/push-notifications/${appUrl}/messages`}>
              Push Notifications
            </Link>
          </li>
          <li>
            Message Details
          </li>
        </ul>
        <Banner title="Message Details" />
        <h2>
          {message.name}
          {message.status === 'Draft' && (
            <span className={classes.lightGrey}>
              {' '}
              (Draft)
            </span>
          )}
        </h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.heading}>
                <strong>
                  Sent To:
                </strong>
              </TableCell>
              <TableCell>
                {message.target_platforms.split(',').join(', ')}
              </TableCell>
              <TableCell className={classes.heading}>
                <strong>
                  Topic:
                </strong>
              </TableCell>
              <TableCell>
                {topicName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.heading}>
                <strong>
                  Message:
                </strong>
              </TableCell>
              <TableCell colSpan={3}>
                {message.message}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.heading}>
                <strong>
                  Sent at:
                </strong>
              </TableCell>
              <TableCell colSpan={3}>
                {moment(message.sent_at).tz(message.timezone).format('LLLL')}
                {' '}
                {moment.tz.zone(message.timezone).abbr(moment().unix())}
                {' '}
                <em className={classes.lightGrey}>
                  {`(${message.timezone})`}
                </em>
              </TableCell>
            </TableRow>
            {message.link_url
              && (
                <TableRow>
                  <TableCell className={classes.heading}>
                    <strong>
                      Link Url:
                    </strong>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <a rel="noopener noreferrer" target="_blank" href={message.link_url}>
                      {message.link_url}
                    </a>
                  </TableCell>
                </TableRow>
              )
            }
            {message.media_url
              && (
                <TableRow>
                  <TableCell className={classes.heading}>
                    <strong>
                      Image Preview:
                    </strong>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <img alt="Preview" className="image-preview__review-img" src={message.media_url} />
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
        <div>
          <h2>
            Message Analytics
          </h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Status
                </TableCell>
                <TableCell align='right'>
                  Sent
                </TableCell>
                <TableCell align='right'>
                  Received
                </TableCell>
                <TableCell align='right'>
                  Clicked
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {message.status}
                </TableCell>
                <TableCell align='right'>
                  {message.send_count_ios + message.send_count_android}
                </TableCell>
                <TableCell align='right'>
                  {message.events.received}
                </TableCell>
                <TableCell align='right'>
                  {message.events.clicked}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MessageDetail);
