import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { logoutUser } from '../../actions/logoutActions';
import { getUser } from '../../helpers';
import Logo from './logo.png';

class Header extends Component {
  componentWillMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      const user = getUser();
      this.setState({
        user,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isAuthenticated } = this.props;
    if ((nextProps.isAuthenticated) && (isAuthenticated !== nextProps.isAuthenticated)) {
      const user = getUser();
      this.setState({
        user,
      });
    }
  }

  render() {
    const { isAuthenticated, logoutUser } = this.props;
    return (
      <nav className="app__sidebar">
        <NavLink exact to="/">
          <img src={Logo} alt="logo" />
        </NavLink>
        {isAuthenticated
          && (
            <div>
              <h2 className="client-name">
                {this.state.user.name}
              </h2>
              <ul className="sidebar-links">
                {/* <li><NavLink exact to="/">Dashboard</NavLink></li>
                <li><NavLink to="/analytics">Analytics</NavLink></li>
                <li><NavLink to="/news">News Feed/Blog</NavLink></li>
                <li><NavLink to="/apps">Apps</NavLink></li>
                <li>
                  <NavLink to="/ams">
                    AMS
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="/push-notifications">
                    Push Notifications
                  </NavLink>
                </li>
                {/* <li><NavLink to="/support">Submit Support Ticket</NavLink></li> */}
              </ul>
              <Button onClick={() => logoutUser()} className="logout">
                Log out
              </Button>
            </div>
          )
        }
      </nav>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logoutUser,
}, dispatch);

export default connect(null, mapDispatchToProps, null, { pure: false })(Header);
