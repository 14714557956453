import React, { Component } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Android from '../../../svg/android-logo.svg';
import Apple from '../../../svg/apple-logo.svg';


export default class SelectPlatform extends Component {
  state = {
    error: '',
  }

  render() {
    const { message, handlePlatformChange } = this.props;
    const { target_platforms } = message;
    return (
      <div className="platform-select__wrapper">
        <div className="platform-select__checkboxes">
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Select Platforms
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={target_platforms.indexOf('Android') > -1} onClick={() => handlePlatformChange('Android')} value="Android" />
                }
                label="Google Android"
              />
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={target_platforms.indexOf('iOS') > -1} onClick={() => handlePlatformChange('iOS')} value="iOS" />
                }
                label="Apple iOS"
              />
            </FormGroup>
            <FormHelperText>
              {this.state.error}
            </FormHelperText>
          </FormControl>
        </div>
        <div className={`platform-select__images ${target_platforms.length === 2 ? 'two-up' : 'single'}`}>
          <img alt="Android" className={`platform-logo android ${target_platforms.includes('Android') ? 'visible' : ''}`} src={Android} />
          <img alt="iOS" className={`platform-logo apple ${target_platforms.includes('iOS') ? 'visible' : ''}`} src={Apple} />
        </div>
      </div>
    );
  }
}
