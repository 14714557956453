import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from '../Login';
// import Dashboard from '../Dashboard';

// Push Messaging Routes
import Push from '../Push';
import MessageList from '../Push/MessageList';
import CreateMessage from '../Push/CreateMessage';
import MessageDetail from '../Push/MessageDetail';
import TopicList from '../Push/TopicList';


const Main = ({ dispatch, isAuthenticated, errorMessage }) => (
  <main className="app__main">
    <Switch>
      <Route path="/login" render={() => <Login isAuthenticated={isAuthenticated} errorMessage={errorMessage} />} />
      <ProtectedRoute authed={isAuthenticated} exact path="/" component={Push} />
      {/* <ProtectedRoute authed={isAuthenticated} path="/analytics" component={Analytics} />
      <ProtectedRoute authed={isAuthenticated} path="/news" component={News} />
      <ProtectedRoute authed={isAuthenticated} path="/apps" component={Apps} />
      <ProtectedRoute authed={isAuthenticated} path="/ams" component={Ams} />
      <ProtectedRoute authed={isAuthenticated} path="/support" component={Support} /> */}
      <ProtectedRoute authed={isAuthenticated} exact path="/push-notifications" component={Push} />
      <ProtectedRoute authed={isAuthenticated} path="/push-notifications/:appUrl/create-message" component={CreateMessage} />
      <ProtectedRoute authed={isAuthenticated} path="/push-notifications/:appUrl/topics" component={TopicList} />
      <ProtectedRoute authed={isAuthenticated} path="/push-notifications/:appUrl/messages" component={MessageList} />
      <ProtectedRoute authed={isAuthenticated} exact path="/push-notifications/:appUrl/message/:id" component={MessageDetail} />
      <ProtectedRoute authed={isAuthenticated} exact path="/push-notifications/:appUrl/edit/:id" component={CreateMessage} />
      <Redirect to="/" />
    </Switch>
  </main>
);

const ProtectedRoute = ({ component: Component, authed, ...rest }) => (
  <Route
    {...rest}
    render={props => (authed === true
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)}
  />
);

export default Main;
