import React from 'react';
import moment from 'moment-timezone';
import { DateTimePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core/styles';
import DateRange from '@material-ui/icons/DateRange';
import AccessTime from '@material-ui/icons/AccessTime';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { timezoneList } from '../../../helpers';

const timezones = timezoneList();
const userTimeZone = moment.tz.guess();

const styles = theme => ({
  scheduleWrapper: {
    display: 'flex',
  },
  checkBoxes: {
    minWidth: '33%',
  },
  inputWrapper: {
    width: '66%',
    display: 'flex',
  },
  formControl: {
    marginLeft: theme.spacing.unit * 2,
  },
});

const ScheduleDelivery = ({
  message, sendNow, toggleSendNow, handleDateChange, handleMessageChange, classes,
}) => (
    <div className={classes.scheduleWrapper}>
      <div className={classes.checkBoxes}>
        <FormControl component="fieldset">
          <FormControlLabel
            control={(
              <Radio
                color="primary"
                checked={sendNow === true}
                onChange={toggleSendNow}
                value="true"
                name="radio-button-demo"
                aria-label="B"
              />
            )}
            label="Send Now"
          />
          <FormControlLabel
            control={(
              <Radio
                color="primary"
                checked={sendNow === false}
                onChange={toggleSendNow}
                value="false"
                name="radio-button-demo"
                aria-label="B"
              />
            )}
            label="Set Date & Time"
          />
        </FormControl>
      </div>
      {!sendNow && (
        <div className={classes.inputWrapper}>
          <DateTimePicker
            dateRangeIcon={<DateRange />}
            timeIcon={<AccessTime />}
            leftArrowIcon={<KeyboardArrowLeft />}
            rightArrowIcon={<KeyboardArrowRight />}
            minDate={moment.tz(message.timezone).format('YYYY-MM-DD HH:mm:ss')}
            value={message.scheduled_send}
            invalidDateMessage="Please choose a time at least 15 minutes from now"
            format="MM/DD/YYYY [at] h:mm A"
            disablePast
            onChange={handleDateChange}
            label="Schedule Delivery"
            showTodayButton
          />
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="timezone">
              Timezone
            </InputLabel>
            <Select
              onChange={handleMessageChange('timezone')}
              displayEmpty
              value={message.timezone}
              name="timezone"
              color="primary"
              id="timezone"
            >
              <MenuItem divider value={userTimeZone}>
                {userTimeZone}
              </MenuItem>
              {timezones.map(timezone => (
                <MenuItem key={timezone} value={timezone}>
                  {timezone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>)
      }
    </div>);

export default withStyles(styles, { withTheme: true })(ScheduleDelivery);
