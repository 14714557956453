import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import SentimentVerySatisfied from '@material-ui/icons/SentimentVerySatisfied';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'emoji-mart/css/emoji-mart.css';
import data from 'emoji-mart/data/apple.json';
import { NimblePicker } from 'emoji-mart';
import ReactS3Uploader from 'react-s3-uploader';

const modalStyle = () => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

const styles = theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  nameField: {
    width: '100%',
  },
  formControl: {
    marginTop: theme.spacing.unit,
    width: '100%',
  },
  uploading: {
    marginRight: theme.spacing.unit * 2,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit,
  },
});

class ComposeMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
    };
  }

  openFileDialog = () => {
    this.fileUploader.click();
  }

  uploadStart = () => {
    this.setState({
      uploading: true,
    });
  }

  removeImg = () => {
    const { handleSetValue } = this.props;
    this.fileUploader.value = '';
    handleSetValue(null, 'media_url');
  }

  handleImgUpload = (signResult) => {
    const { handleSetValue } = this.props;
    const mediaUrl = signResult.signedUrl.substring(0, signResult.signedUrl.indexOf('?'));
    handleSetValue(mediaUrl, 'media_url');
    this.setState({
      uploading: false,
    });
  }

  isUrlValid = (name) => {
    const { message } = this.props;
    if (message[name]) {
      const res = message[name].match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      if (res == null) { return true; }
    }
    return false;
  }

  render() {
    const {
      classes, topics, message, handleMessageChange, toggleModalOpen, modalOpen, theme, appendEmoji, appUrl,
    } = this.props;
    const { uploading } = this.state;
    return (
      <div>
        <Modal disableAutoFocus keepMounted open={modalOpen} onClose={() => toggleModalOpen(false)}>
          <NimblePicker style={modalStyle()} onClick={(emoji, event) => appendEmoji(emoji)} set="apple" data={data} title="Pick your emoji…" emoji="point_up" color={theme.palette.primary.main} />
        </Modal>
        <form className={classes.root}>
          <TextField
            required
            className={classes.nameField}
            id="name"
            label="Name"
            helperText="For Reference Purposes Only (Required)"
            margin="normal"
            value={message.name || ''}
            onChange={handleMessageChange('name')}
          />
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="topic">
              Topic
            </InputLabel>
            <Select
              value={message.topic_id || ''}
              onChange={handleMessageChange('topic_id')}
              displayEmpty
              name="topic"
              color="primary"
            >
              <MenuItem value="">
                <em>
                  None
                </em>
              </MenuItem>
              {topics.map(topic => (
                <MenuItem key={topic.id} value={topic.id}>
                  {topic.display_name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              (Optional)
            </FormHelperText>
          </FormControl>
          <TextField
            fullWidth
            id="message"
            placeholder="This is the body of your text message"
            value={message.message || ''}
            required
            multiline
            label="Message"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Insert Emoji"
                    onClick={toggleModalOpen}
                  >
                    <Tooltip title="Insert Emoji" placement="top">
                      <SentimentVerySatisfied />

                    </Tooltip>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 140,
            }}
            helperText={`${message.message.length} / 140 Character Max (Required)`}
            onChange={handleMessageChange('message')}
          />
          <TextField
            className={classes.nameField}
            id="linkUrl"
            label="Link URL"
            helperText="Link URL (Optional)"
            margin="normal"
            error={this.isUrlValid('link_url')}
            value={message.link_url || ''}
            onChange={handleMessageChange('link_url')}
          />
          <TextField
            className={classes.formControl}
            id="media_url"
            value={message.media_url || ''}
            label="Image URL"
            disabled={uploading}
            error={this.isUrlValid('media_url')}
            onChange={handleMessageChange('media_url')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">

                  {uploading ? (
                    <CircularProgress className={classes.uploading} size={20} thickness={7} />
                  ) : (
                      <IconButton
                        aria-label="Upload an Image"
                        onClick={this.openFileDialog}
                      >
                        <Tooltip title="Upload an Image" placement="top">
                          <AddPhotoAlternate />
                        </Tooltip>
                      </IconButton>
                    )
                  }

                </InputAdornment>
              ),
            }}
            helperText="Include an Image URL or Upload an Image (Optional)"
          />


          <div style={{ display: 'none' }}>
            <ReactS3Uploader
              signingUrl="/s3/sign"
              signingUrlMethod="GET"
              accept="image/*"
              s3path={`${appUrl}/uploads/`}
              uploadRequestHeaders={{
                'x-amz-acl': 'public-read',
              }}
              inputRef={(el) => { this.fileUploader = el; }}
              signingUrlWithCredentials
              // onSignedUrl={this.handleImgUpload}
              onProgress={this.uploadStart}
              // onError={this.onUploadError}
              onFinish={this.handleImgUpload}
              contentDisposition="auto"
              scrubFilename={filename => filename.replace(/[^\w\d_\-.]+/ig, '')}
              autoUpload
            />
          </div>
          {message.media_url
            && (
              <div className="image-preview">
                <img alt="Media Preview" src={message.media_url} />
                <div className="image-buttons">
                  <Tooltip title="Remove Image">
                    <Fab size="medium" color="secondary" aria-label="Remove Image" onClick={() => { this.removeImg(); }}>
                      <DeleteIcon />
                    </Fab>
                  </Tooltip>
                </div>
              </div>
            )
          }
        </form>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ComposeMessage);
