import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { loginUser } from '../../actions/loginActions';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      showPassword: false,
    };
  }

  handleChange = name => (e) => {
    this.setState({
      [name]: e.target.value.trim(),
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    if (email !== '' && password !== '') {
      const creds = { email, password };
      this.props.loginUser(creds);
    }
  };

  render() {
    const { errorMessage } = this.props;
    return (
      <div className="login__wrapper">
        <form className="login__form" onSubmit={this.handleSubmit}>
          <h1>
            Welcome to the Client Portal
          </h1>
          <TextField
            id="email"
            label="Username"
            value={this.state.email}
            onChange={this.handleChange('email')}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth>
            <InputLabel htmlFor="adornment-password">
              Password
            </InputLabel>
            <Input
              id="adornment-password"
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.password}
              onChange={this.handleChange('password')}

              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
            />

          </FormControl>
          <div className="form-footer">
            {/* <a href="/">
              Forgot Password?
            </a> */}
            <Button type="submit" variant="contained" color="primary" className="sign-in-button">
              Sign In
            </Button>

          </div>
          <p className="error-message">
            {errorMessage}
            {' '}
            &nbsp;
          </p>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  loginUser,
}, dispatch);

export default connect(null, mapDispatchToProps)(Login);
