import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Table, TableBody, TableCell, TableHead, TableFooter, TablePagination, TableRow,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import SmsIcon from '@material-ui/icons/Sms';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Banner from '../layout/Banner';
import Pagination from '../Table/Pagination';
import LinkButton from '../LinkButton';

const styles = {
  createdAt: {
    width: '20%',
  },
  msgName: {
    width: '30%',
  },
  topicName: {
    width: '20%',
  },
  action: {
    width: '50px',
  },
  noMessages: {
    textAlign: 'center',
  },
  lightGrey: {
    color: '#9B9B9B',
  },
};

class MessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      query: '',

      columnToQuery: 'name',
      sortKey: 'created_at',
      order: 'desc',
      loading: true,
      page: 0,
      rowsPerPage: 5,
    };
  }

  componentDidMount = async () => {
    const { appUrl } = this.props.match.params;
    const appResponse = await fetch(`/api/app/${appUrl}`);
    const app = await appResponse.json();
    const messageResponse = await fetch(`/api/apps/${appUrl}/messages`);
    const messageData = await messageResponse.json();
    const { messages } = messageData;
    this.setState({
      messages,
      ...app,
      loading: false,
    });
  };

  updateQuery = (e) => {
    this.setState({
      query: e.target.value,
    });
  }

  updateSort = (e) => {
    this.setState({
      order: e.target.value,
    });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  goBack = () => {
    this.props.history.goBack();
  };


  render() {
    const { classes } = this.props;
    const {
      loading, page, messages, rowsPerPage, url, name, deviceCount, query, sortKey, order,
    } = this.state;
    const lowerCaseQuery = this.state.query.toLowerCase();
    const filteredMessages = messages.filter(message => message[this.state.columnToQuery]
      .toLowerCase()
      .includes(lowerCaseQuery));
    const sortedMessages = _.orderBy(filteredMessages, [sortKey], [order]);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, sortedMessages.length - page * rowsPerPage);
    if (loading) return null;
    return (
      <div className="message-list__container">
        <ul className="breadcrumbs">
          <li>
            <Link to={`/push-notifications/${url}/messages`}>
              {name}
            </Link>
          </li>
          <li>
            Push Notifications
          </li>
        </ul>
        <Banner title="Push Notifications" />

        <div className="message-list__toolbar">
          <div className="message-list__filter-sort">
            <FormControl>
              <InputLabel shrink htmlFor="topic">
                Sort
              </InputLabel>
              <Select
                value={order}
                onChange={this.updateSort}
                displayEmpty
                name="order"
                color="primary"
              >
                <MenuItem value="desc">
                  Date Created (Newest)
                </MenuItem>
                <MenuItem value="asc">
                  Date Created (Oldest)
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              className="fs-item"
              label="Search Messages"
              value={query}
              onChange={this.updateQuery}
            />
          </div>
          <div>
            <LinkButton color="primary" variant="contained" to={`/push-notifications/${url}/create-message`}>
              Create Message
            </LinkButton>
            <LinkButton color="primary" variant="contained" to={`/push-notifications/${url}/topics`}>
              View Topics
            </LinkButton>
          </div>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}>
                <h2>
                  {name}
                </h2>
              </TableCell>
              <TableCell colSpan={2} align='right'>
                <h3>
                  Device Count:
                  {' '}
                  <strong>
                    {deviceCount}
                  </strong>
                </h3>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.createdAt}>
                Date Created
              </TableCell>
              <TableCell className={classes.msgName}>
                Name
              </TableCell>
              <TableCell className={classes.topicName}>
                Topic
              </TableCell>
              <TableCell align='right'>
                Sends
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell className={classes.action} />
            </TableRow>
          </TableHead>
          {sortedMessages.length !== 0 ? (
            <TableBody>
              {sortedMessages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(message => (
                <TableRow key={message.id}>
                  <TableCell>
                    <strong>
                      {moment(message.created_at).format('MM/DD/YYYY')}
                    </strong>
                  </TableCell>
                  <TableCell>
                    {message.name}
                  </TableCell>
                  {message.topic_name ? (
                    <TableCell>
                      {message.topic_name}
                    </TableCell>

                  ) : (
                      <TableCell>
                        <em className={classes.lightGrey}>
                          No Topic
                        </em>
                      </TableCell>
                    )}
                  {message.status === 'Sent' ? (
                    <TableCell align='right'>
                      {message.send_count_ios + message.send_count_android}
                    </TableCell>
                  ) : (
                      <TableCell align='right'>
                        <em className={classes.lightGrey}>
                          N/A
                        </em>
                      </TableCell>
                    )}
                  <TableCell>
                    {message.status}
                  </TableCell>
                  <TableCell>
                    {message.status !== 'Sent' ? (
                      <Tooltip title="Edit Message">
                        <Link to={`/push-notifications/${url}/edit/${message.id}`}>
                          <IconButton aria-label="Edit">
                            <EditIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    ) : (
                        <Tooltip title="View Message">
                          <Link to={`/push-notifications/${url}/message/${message.id}`}>
                            <IconButton aria-label="View">
                              <SmsIcon />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      )}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 57 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
              <TableBody>
                {emptyRows > 0 && (
                  <TableRow style={{ height: 57 * emptyRows }}>
                    <TableCell colSpan={6} className={classes.noMessages}>
                      <h3>
                        You currently have
                        {' '}
                        <strong>
                          0
                        </strong>
                        {' '}
                        push notifications.
                      </h3>
                      <LinkButton color="primary" variant="contained" to={`/push-notifications/${url}/create-message`}>
                        Create Message
                      </LinkButton>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={6}
                count={sortedMessages.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={Pagination}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MessageList);
