import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Table, TableBody, TableCell, TableHead, TableFooter, TablePagination, TableRow, Button, Fab,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import CreateTopic from './CreateTopic';
import Banner from '../layout/Banner';
import Pagination from '../Table/Pagination';

const styles = {
  createdAt: {
    width: '20%',
  },
  msgName: {
    width: '30%',
  },
  topicName: {
    width: '20%',
  },
  action: {
    width: '50px',
  },
  noMessages: {
    textAlign: 'center',
  },
  lightGrey: {
    color: '#9B9B9B',
  },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class TopicList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: [],
      page: 0,
      rowsPerPage: 5,
      loading: true,
      editing: false,
      openAddTopic: false,
      dialogOpen: false,
      selectedTopic: {},
    };
  }

  componentDidMount = async () => {
    this.getTopics();
  };

  getTopics = async () => {
    const { appUrl } = this.props.match.params;
    try {
      const appResponse = await fetch(`/api/app/${appUrl}`);
      const app = await appResponse.json();
      const topicResponse = await fetch(`/api/apps/${appUrl}/topics`);
      const topicData = await topicResponse.json();
      const topics = await this.getSubscribers(topicData.topics);
      console.log(topics);
      this.setState({
        app,
        topics,
        loading: false,
      });
    } catch (err) {
      console.log(`Error: Could not fetch topics: ${err}`);
    }
  };

  getSubscribers = async (topics) => {
    const topicArray = topics.map(async (topic) => {
      const response = await fetch(`/api/apps/${topic.id}/subscribers`);
      const subscribertopics = await response.json();
      const topicWithSubscribers = {
        ...topic,
        users: subscribertopics.subscriptions,
      };
      return topicWithSubscribers;
    });
    const topicsWithSubscribers = await Promise.all(topicArray);
    return topicsWithSubscribers;
  };

  toggleAdd = () => {
    const { openAddTopic } = this.state;
    this.setState({
      openAddTopic: !openAddTopic,
    });
  }

  handleDialogOpen = (topic) => {
    this.setState({
      selectedTopic: topic,
      dialogOpen: true,
      editing: false,
    });
  };

  editTopic = (topic) => {
    this.setState({
      selectedTopic: topic,
      editing: true,
    });
  };

  handleChange = name => (e) => {
    const { selectedTopic } = this.state;
    this.setState({
      selectedTopic: {
        ...selectedTopic,
        [name]: e.target.value,
      },
    });
  }

  toggleOptIn = () => {
    const { selectedTopic } = this.state;
    this.setState({
      selectedTopic: {
        ...selectedTopic,
        default_opt_in: !selectedTopic.default_opt_in,
        updated: true,
      },
    });
  }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteTopic = async (id) => {
    const payload = {
      topicId: id,
    };
    const deleteRequest = await fetch('/api/push/deleteTopic', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const results = await deleteRequest.json();
    if (results.success) {
      this.getTopics();
      this.handleDialogClose();
    }
  }

  createTopic = async (topic) => {
    const payload = JSON.stringify(topic);
    const messageRequest = await fetch('/api/push/createTopic', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: payload,
    });
    const results = await messageRequest.json();
    if (results.success) {
      this.getTopics();
      this.toggleAdd();
    }
  };

  updateTopic = async (topic) => {
    const { users, updated, ...updatedTopic } = topic;
    const payload = JSON.stringify(updatedTopic);
    const messageRequest = await fetch('/api/push/updateTopic', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: payload,
    });
    const results = await messageRequest.json();
    if (results.success) {
      this.getTopics();
      this.cancelEdit();
    }
  };

  validateTopic = () => {
    const { selectedTopic, topics } = this.state;
    const matches = _.filter(topics, { display_name: selectedTopic.display_name });
    if ((selectedTopic.display_name.length !== 0) && (selectedTopic.description.length !== 0) && (matches.length === 0) || selectedTopic.updated) {
      return false;
    }
    return true;
  }


  cancelEdit = () => {
    this.setState({
      selectedTopic: {},
      editing: false,
    });
  }

  goBack = () => {
    this.props.history.goBack();
  };


  render() {
    const { classes } = this.props;
    const {
      loading, page, rowsPerPage, app, topics, openAddTopic, selectedTopic, editing,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, topics.length - page * rowsPerPage);
    if (loading) return null;
    return (
      <div>
        <ul className="breadcrumbs">
          <li>
            <Link to={`/push-notifications/${app.url}/messages`}>
              {app.name}
            </Link>
          </li>
          <li>
            <Link to={`/push-notifications/${app.url}/messages`}>
              Push Notifications
            </Link>
          </li>
          <li>
            Topics
          </li>
        </ul>
        <Banner title="Topics" />
        <CreateTopic app={app} topics={topics} showForm={openAddTopic} createTopic={this.createTopic} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}>
                <h2>
                  {app.name}
                </h2>
              </TableCell>
              <TableCell colSpan={2} align="right">
                {openAddTopic ? (
                  <Fab onClick={this.toggleAdd} size="small" color="primary" aria-label="Close">
                    <CancelIcon />
                  </Fab>

                ) : (
                  <Tooltip title="Add A Topic">
                      <Fab onClick={this.toggleAdd} size="small" color="primary" aria-label="Add">
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                )}

              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Description
              </TableCell>
              <TableCell>
                Default Opt-In
              </TableCell>
              <TableCell align="right">
                Users
              </TableCell>
              <TableCell align="right">
                Edit/Delete
              </TableCell>
            </TableRow>
          </TableHead>
          {topics.length !== 0 ? (
            <TableBody>
              {topics.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(topic => ((editing && (selectedTopic.id === topic.id))
                ? (
                  <TableRow key={topic.name}>
                    <TableCell>
                      <TextField
                        required
                        id="display-name"
                        placeholder="Topic Name"
                        value={selectedTopic.display_name}
                        onChange={this.handleChange('display_name')}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        required
                        id="description"
                        placeholder="Topic Description"
                        value={selectedTopic.description}
                        onChange={this.handleChange('description')}
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={selectedTopic.default_opt_in}
                        value="default_opt_in"
                        onClick={this.toggleOptIn}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="right">
                      {topic.users}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton aria-label="Save Changes" disabled={this.validateTopic()} onClick={() => this.updateTopic(selectedTopic)}>
                        <CheckIcon />
                      </IconButton>
                      <IconButton aria-label="Cancel" onClick={this.cancelEdit}>
                        <CancelIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
                : (
                  <TableRow key={topic.name}>
                    <TableCell>
                      <strong>
                        {topic.display_name}
                      </strong>
                    </TableCell>
                    <TableCell>
                      {topic.description}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={topic.default_opt_in}
                        disabled
                        value="default_opt_in"
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="right">
                      {topic.users}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Edit Topic">
                        <IconButton aria-label="Edit" onClick={() => this.editTopic(topic)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Topic">
                        <IconButton aria-label="Delete" onClick={() => this.handleDialogOpen(topic)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 57 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
                {emptyRows > 0
                  && (
                    <TableRow style={{ height: 57 * emptyRows }}>
                      <TableCell colSpan={5} className={classes.noMessages}>
                        <h3>
                          You currently have
                          {' '}
                          <strong>
                            0
                          </strong>
                          {' '}
                          topics.
                        </h3>
                        <Button disabled={openAddTopic} color="primary" variant="contained" onClick={this.toggleAdd}>
                          Create A Topic
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={5}
                count={topics.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={Pagination}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <Dialog
          open={this.state.dialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {'Delete Topic?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to delete
              <strong>
                {` "${selectedTopic.display_name}" `}
              </strong>
              and all messages associated with that topic?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose}>
              Cancel
            </Button>
            <Button onClick={() => this.handleDeleteTopic(selectedTopic.id)} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TopicList);
