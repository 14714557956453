import React from 'react'
import moment from 'moment'

const Footer = () => {
  return (
    <footer className="app__footer">
      <ul>
        <li>&copy; {moment().format("YYYY")} jācapps - </li>
        <li>Privacy Policy - </li>
        <li>Terms of Use</li>
      </ul>
    </footer>
  )
}

export default Footer;
