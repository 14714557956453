import React, { Component } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Edit from '@material-ui/icons/Edit';
import {
  Table, TableBody, TableCell, TableRow,
} from '@material-ui/core';

const styles = {
  heading: {
    width: '20%',
  },
  action: {
    width: '50px',
    textAlign: 'right',
  },
  lightGrey: {
    color: '#9B9B9B',
  },
};

class Review extends Component {
  findTopic = (topicId) => {
    const { topics } = this.props;
    if (!topicId) return 'No Topic';
    const topic = _.find(topics, { id: topicId });
    return topic.display_name;
  };

  render() {
    const {
      message, sendNow, goToStep, classes,
    } = this.props;
    const topicName = this.findTopic(message.topic_id);
    return (
      <div>
        <h2>
          {message.name}
          {message.status === 'Draft' && (
            <span className={classes.lightGrey}>
              {' '}
              (Draft)
            </span>
          )}
        </h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.heading}>
                <strong>
                  Send To:
                </strong>
              </TableCell>
              <TableCell>
                {message.target_platforms.join(', ')}
              </TableCell>
              <TableCell className={classes.action}>
                <IconButton aria-label="Edit Platforms" onClick={() => goToStep(0)}>
                  <Tooltip title="Edit Message Platforms" placement="top">
                    <Edit />
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.heading}>
                <strong>
                  Topic:
                </strong>
              </TableCell>
              <TableCell>
                {topicName}
              </TableCell>
              <TableCell className={classes.action}>
                <IconButton aria-label="Edit Topic" onClick={() => goToStep(1)}>
                  <Tooltip title="Change Topic" placement="top">
                    <Edit />
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.heading}>
                <strong>
                  Message:
                </strong>
              </TableCell>
              <TableCell>
                {message.message}
              </TableCell>
              <TableCell className={classes.action}>
                <IconButton aria-label="Edit Message" onClick={() => goToStep(1)}>
                  <Tooltip title="Edit Message" placement="top">
                    <Edit />
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.heading}>
                <strong>
                  Scheduled:
                </strong>
              </TableCell>
              {sendNow ? (
                <TableCell>
                  Send Immediately
                </TableCell>
              ) : (
                <TableCell>
                    {moment.tz(message.scheduled_send, 'YYYY-MM-DD HH:mm:ss', message.timezone).format('LLLL')}
                    {' '}
                    {moment.tz.zone(message.timezone).abbr(moment().unix())}
                    {' '}
                    <em className={classes.lightGrey}>
                      {`(${message.timezone})`}
                    </em>
                  </TableCell>
              )}
              <TableCell className={classes.action}>
                <IconButton aria-label="Change Scheduling" onClick={() => goToStep(2)}>
                  <Tooltip title="Edit Scheduled Send" placement="top">
                    <Edit />
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
            {message.link_url
              && (
                <TableRow>
                  <TableCell className={classes.heading}>
                    <strong>
                      Link Url:
                    </strong>
                  </TableCell>
                  <TableCell>
                    <a rel="noopener noreferrer" target="_blank" href={message.link_url}>
                      {message.link_url}
                    </a>
                  </TableCell>
                  <TableCell className={classes.action}>
                    <IconButton aria-label="Edit Link Url" onClick={() => goToStep(1)}>
                      <Tooltip title="Edit Link Url" placement="top">
                        <Edit />
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            }
            {message.media_url
              && (
                <TableRow>
                  <TableCell className={classes.heading}>
                    <strong>
                      Image Preview:
                    </strong>
                  </TableCell>
                  <TableCell>
                    <img alt="Preview" className="image-preview__review-img" src={message.media_url} />
                  </TableCell>
                  <TableCell className={classes.action}>
                    <IconButton aria-label="Edit Image" onClick={() => goToStep(1)}>
                      <Tooltip title="Edit Image" placement="top">
                        <Edit />
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Review);
