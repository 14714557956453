import decode from 'jwt-decode';

export const getUser = () => {
  const token = localStorage.getItem('id_token');
  const user = decode(token);
  return user;
};

export const timezoneList = () => {
  const timezones = [
    'America/Puerto_Rico',
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'Pacific/Honolulu',
  ];
  return timezones;
};

export const slugify = text => text.toString().toLowerCase().trim()
  .replace(/&/g, '-and-')
  .replace(/[\s\W-]+/g, '-');
