import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import Sidebar from './components/layout/Sidebar';
import Main from './components/layout/Main';
import Footer from './components/layout/Footer';
import './styles/App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#06A4F0',
      contrastText: 'white',
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      '"Lato"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const App = ({ dispatch, isAuthenticated, errorMessage }) => (
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="app__wrapper">
          <Sidebar isAuthenticated={isAuthenticated} dispatch={dispatch} />
          <Main isAuthenticated={isAuthenticated} dispatch={dispatch} errorMessage={errorMessage} />
        </div>
        <Footer />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </BrowserRouter>
);

function mapStateToProps(state) {
  const { auth } = state;
  const { isAuthenticated, errorMessage } = auth;

  return {
    isAuthenticated,
    errorMessage,
  };
}

export default connect(mapStateToProps)(App);
