export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

const requestLogin = creds => ({
  type: LOGIN_REQUEST,
  isFetching: true,
  isAuthenticated: false,
  creds,
});

const receiveLogin = user => ({
  type: LOGIN_SUCCESS,
  isFetching: false,
  isAuthenticated: true,
  token: user.token,
});

const loginError = message => ({
  type: LOGIN_FAILURE,
  isFetching: false,
  isAuthenticated: false,
  message,
});

export function loginUser(creds) {
  const config = {
    method: 'POST',
    body: JSON.stringify({
      email: creds.email,
      password: creds.password,
    }),
    headers: { 'Content-Type': 'application/json' },
  };

  return (dispatch) => {
    dispatch(requestLogin(creds));
    return fetch('/userAuth', config)
      .then(response => response.json())
      .then(({ success, user }) => {
        if (!success) {
          dispatch(loginError(user.message));
          return Promise.reject(user);
        }
        localStorage.setItem('id_token', user.token);
        localStorage.setItem('access_token', user.token);
        dispatch(receiveLogin(user));
        window.location.href = '/';
      }).catch(err => console.log('Error: ', err));
  };
}
