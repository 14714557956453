import React, { Component } from 'react';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { slugify } from '../../helpers';

const defaultTopic = {
  description: '',
  default_opt_in: true,
  display_name: '',
};

export default class CreateTopic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topic: defaultTopic,
    };
  }

  toggleOptIn = () => {
    const { topic } = this.state;
    this.setState({
      topic: {
        ...topic,
        default_opt_in: !topic.default_opt_in,
      },
    });
  }

  validateTopic = () => {
    const { topics } = this.props;
    const { topic } = this.state;
    const matches = _.filter(topics, { display_name: topic.display_name });
    if ((topic.display_name.length !== 0) && (topic.description.length !== 0) && (matches.length === 0)) {
      return false;
    }
    return true;
  }

  handleChange = name => (e) => {
    const { topic } = this.state;
    this.setState({
      topic: {
        ...topic,
        [name]: e.target.value,
      },
    });
  }

  submitTopic = (e) => {
    // get new topic,
    const { topic } = this.state;
    const { app, topics, createTopic } = this.props;
    let name = `${app.url}_${slugify(topic.display_name)}`;
    const matches = _.filter(topics, { name });
    if (matches.length > 0) {
      name = `${app.url}_${slugify(topic.display_name)}_${Math.floor(Date.now() / 1000)}`;
    }
    const payload = {
      app_id: app.app_id,
      name,
      ...topic,
    };
    createTopic(payload);
    this.setState({
      topic: defaultTopic,
    });
  }

  render() {
    const { showForm } = this.props;
    const { topic, error } = this.state;
    return (
      <div className={`create-topic__form ${showForm ? 'visible' : 'hidden'}`}>
        <TextField
          required
          error={error}
          className="txfield"
          id="display-name"
          label="Topic Name"
          helperText="Name of Topic (Required)"
          margin="normal"
          value={topic.display_name || ''}
          onChange={this.handleChange('display_name')}
        />
        <TextField
          required
          className="txfield"
          id="description"
          label="Topic Description"
          helperText="A Brief Description of the Topic (Required)"
          margin="normal"
          value={topic.description || ''}
          onChange={this.handleChange('description')}
        />
        <FormControlLabel
          control={(
            <Switch
              checked={topic.default_opt_in}
              value="default_opt_in"
              onClick={this.toggleOptIn}
              color="primary"
            />
          )}
          label="Default Opt-In"
        />
        <Button color="primary" disabled={this.validateTopic()} onClick={this.submitTopic} variant="contained">
          Create Topic
        </Button>
      </div>
    );
  }
}
