import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUser } from '../../helpers';
import Banner from '../layout/Banner';

class Push extends Component {
  constructor() {
    super();
    this.state = {
      apps: [],
    };
  }

  componentWillMount() {
    if (this.props.isAuthenticated) {
      const user = getUser();
      this.getApps(user);
      this.setState({
        user,
      });
    }
  }

  getApps = (user) => {
    const { user_id } = user;
    fetch(`/api/apps/${user_id}`)
      .then(response => response.json())
      .then((response) => {
        this.setState({ apps: response.data });
      })
      .catch(err => console.error(err));
  }

  renderApp = ({ app_id, name, url }) =>
    (<div key={app_id}>
      <h3 className="app__name"><Link to={{ pathname: `/push-notifications/${url}/messages`, state: { name, url, app_id } }}>{name}</Link></h3>
    </div>)

  render() {
    const { apps } = this.state;
    return (
      <div className="main app">
        <Banner title="Push Notifications" />
        <div className="container">
          <h2>Select an app to view or send a message.</h2>
          {apps.map(this.renderApp)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  const { isAuthenticated } = auth;

  return {
    isAuthenticated,
  };
}

export default connect(mapStateToProps)(Push);

