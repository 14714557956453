import React, { Component } from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Confetti from 'react-confetti';
import Banner from '../layout/Banner';
import LinkButton from '../LinkButton';

// Steps
import SelectPlatform from './Steps/SelectPlatform';
import ComposeMessage from './Steps/ComposeMessage';
import ScheduleDelivery from './Steps/ScheduleDelivery';
import Review from './Steps/Review';

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  stepNavWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0',
  },
  complete: {
    textAlign: 'center',
  },
});

function isNotEmpty(value) {
  return value.length !== 0;
}

function getSteps() {
  return ['Select Platforms', 'Compose Message', 'Schedule Delivery', 'Review & Send'];
}

function getStepContent(step, props) {
  switch (step) {
    case 0:
      return <SelectPlatform {...props} />;
    case 1:
      return <ComposeMessage {...props} />;
    case 2:
      return <ScheduleDelivery {...props} />;
    case 3:
      return <Review {...props} />;
    default:
      return 'Unknown step';
  }
}

const defaultMessage = {
  target_platforms: [],
  topic_id: null,
  name: '',
  message: '',
  media_url: null,
  link_url: null,
  scheduled_send: '',
  status: 'Draft',
  timezone: moment.tz.guess(),
};

class CreateMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      loading: true,
      saving: false,
      message: defaultMessage,
      modalOpen: false,
      sendNow: true,
    };
  }

  componentDidMount = async () => {
    const { appUrl, id: messageId } = this.props.match.params;
    const topicResponse = await fetch(`/api/apps/${appUrl}/topics`);
    const topicData = await topicResponse.json();
    const appResponse = await fetch(`/api/app/${appUrl}`);
    const app = await appResponse.json();
    const { app_id } = app;
    this.handleSetValue(app_id, 'app_id');
    if (messageId) {
      this.editExistingMessage(appUrl, messageId);
    } else {
      const today = moment().format('YYYY-MM-DD HH:mm:ss');
      this.handleDateChange(today);
    }
    this.setState({
      topics: topicData.topics,
      loading: false,
      appUrl,
      app,
    });
  }

  goToStep = (step) => {
    this.setState({
      activeStep: step,
    });
  };

  cancelMessage = () => {
    const { appUrl } = this.props.match.params;
    this.context.history.push(`/push-notifications/${appUrl}/messages/`);
  }

  editExistingMessage = async (appUrl, id) => {
    const request = await fetch(`/api/apps/${appUrl}/message/${id}`);
    const { message: existingMsg } = await request.json();
    const message = {
      ...existingMsg,
      scheduled_send: moment(existingMsg.scheduled_send).utc().format('YYYY-MM-DD HH:mm:ss'),
      target_platforms: existingMsg.target_platforms.split(','),
    };
    this.setState({
      activeStep: 3,
      sendNow: false,
      message,
    });
  }

  validatePlatforms = () => {
    const { message } = this.state;
    if (message.target_platforms.length === 0) {
      return true;
    }
    return false;
  }

  validateMessage = () => {
    const { message } = this.state;
    const validationArray = [
      message.name,
      message.message,
    ];
    return !validationArray.every(isNotEmpty);
  }

  validateScheduling = () => {
    const { message, sendNow } = this.state;
    const now = moment().tz(message.timezone).valueOf();
    // const sendtime = moment(message.scheduled_send).tz(message.timezone).valueOf();

    const sendtime = moment.tz(message.scheduled_send, message.timezone).valueOf();
    console.log(`Sendtime: ${sendtime}, Now: ${now}. ${sendtime - now}`);
    if ((!sendNow) && (sendtime < now)) {
      return true;
    }
    return false;
  }

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep + 1,
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleChange = name => (e) => {
    this.setState({
      [name]: e.target.value,
    });
  };

  handlePlatformChange = (platform) => {
    let { message } = this.state;
    const platforms = message.target_platforms;
    if (platforms.indexOf(platform) > -1) {
      message = {
        ...message,
        target_platforms: platforms.filter(e => e !== platform),
      };
    } else {
      message = {
        ...message,
        target_platforms: [...platforms, platform],
      };
    }
    this.setState({
      message,
    });
  }

  handleSetValue = (value, name) => {
    let { message } = this.state;
    message = {
      ...message,
      [name]: value,
    };
    this.setState({
      message,
    });
  }

  handleMessageChange = name => (e) => {
    let { message } = this.state;
    message = {
      ...message,
      [name]: e.target.value,
    };
    this.setState({
      message,
    });
  };


  handleDateChange = (date) => {
    const remainder = 15 - (moment(date).minute() % 15);
    let dateTime;
    if (remainder === 15) {
      dateTime = moment(date).format('YYYY-MM-DD HH:mm:00');
    } else {
      dateTime = moment(date).add(remainder, 'minutes').format('YYYY-MM-DD HH:mm:00');
    }
    let { message } = this.state;
    message = {
      ...message,
      scheduled_send: dateTime,
    };
    this.setState({ message });
  }

  toggleSendNow = () => {
    const { sendNow } = this.state;
    this.setState({ sendNow: !sendNow });
  }

  toggleModalOpen = (value) => {
    if (value) {
      this.setState({ modalOpen: value });
    } else {
      const { modalOpen } = this.state;
      this.setState({ modalOpen: !modalOpen });
    }
  }

  appendEmoji = (emoji) => {
    const { message: prevMsg } = this.state;
    if (prevMsg.message.length < 140) {
      const message = `${prevMsg.message.trim()} ${emoji.native}`;
      this.toggleModalOpen();
      this.setState({
        message: {
          ...prevMsg,
          message,
        },
      });
    }
  }

  submitMessage = async (sendNow, isDraft) => {
    const { message: msgObj } = this.state;
    const {
      created_at, utc_sendtime, events, ...message
    } = msgObj;
    this.setState({
      saving: true,
    });
    try {
      const payload = JSON.stringify({
        message, sendNow, isDraft,
      });
      const endpoint = message.id ? '/api/push/updateMessage' : '/api/push/createMessage';
      const messageRequest = await fetch(endpoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: payload,
      });
      let confirmation = 'Success! Your message is scheduled & ready to go!';
      if (isDraft) {
        confirmation = 'Success! Your draft has been saved!';
      }
      if (sendNow) {
        confirmation = 'Success! Your message has been sent!';
      }
      const results = await messageRequest.json();
      if (await results.success) {
        console.log(results);
        this.setState({
          saving: false,
          confirmation,
        });
        this.handleNext();
      } else {
        console.log(results);
      }
    } catch (err) {
      this.setState({
        saving: false,
      });
      console.log(err);
    }
  }

  checkForErrors(step) {
    switch (step) {
      case 0:
        return this.validatePlatforms();
      case 1:
        return this.validateMessage();
      case 2:
        return this.validateScheduling();
      default:
        return false;
    }
  }

  render() {
    const { classes } = this.props;
    const { id: isEditing } = this.props.match.params;
    const steps = getSteps();
    const {
      activeStep, loading, topics, message, sendNow, modalOpen, appUrl, saving, app,
    } = this.state;
    const stepperStyle = {
      backgroundColor: 'transparent',
    };
    const confettiStyle = {
      zIndex: '-1',
    };
    const props = {
      message,
      sendNow,
      appUrl,
      topics,
      modalOpen,
      appendEmoji: this.appendEmoji,
      goToStep: this.goToStep,
      toggleSendNow: this.toggleSendNow,
      handlePlatformChange: this.handlePlatformChange,
      toggleModalOpen: this.toggleModalOpen,
      handleChange: this.handleChange,
      handleMessageChange: this.handleMessageChange,
      handleSetValue: this.handleSetValue,
      handleDateChange: this.handleDateChange,
    };
    if (loading) return null;
    return (
      <div className={classes.root}>
        <ul className="breadcrumbs">
          <li>
            <Link to={`/push-notifications/${appUrl}/messages`}>
              {app.name}
            </Link>
          </li>
          <li>
            <Link to={`/push-notifications/${appUrl}/messages`}>
              Push Notifications
            </Link>
          </li>
          {isEditing ? (
            <li>
              Edit Messages
            </li>
          ) : (
              <li>
                Create New Message
            </li>
            )}
        </ul>
        {isEditing ? (
          <Banner title="Edit Message" />
        ) : (
            <Banner title="Create New Message" />
          )}
        <Stepper activeStep={activeStep} style={stepperStyle}>
          {steps.map((label) => {
            const props = {};
            const labelProps = {};
            return (
              <Step key={label} {...props}>
                <StepLabel {...labelProps}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div className={classes.complete}>
              <Confetti recycle={false} style={confettiStyle} />
              <div>
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
              </div>
              <h2>
                {this.state.confirmation}
              </h2>
              <LinkButton to={`/push-notifications/${appUrl}/messages`}>
                Back To Push Notifications
              </LinkButton>
            </div>
          ) : (
              <div>
                {getStepContent(activeStep, props)}
                <div className={classes.stepNavWrapper}>
                  {activeStep === 0 ? (
                    <LinkButton className={classes.button} to={`/push-notifications/${appUrl}/messages`}>
                      Cancel
                  </LinkButton>
                  ) : (
                      <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        Back
                  </Button>
                    )}

                  {activeStep === steps.length - 1
                    ? (
                      <div className="submit-buttons">
                        <CircularProgress className={`progress ${saving ? 'visible' : 'hidden'}`} size={20} />
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={saving}
                          onClick={() => { this.submitMessage(false, true); }}
                          className={classes.button}
                        >
                          Save Draft
                      </Button>
                        {!sendNow
                          && (
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={(saving) || (this.validateScheduling())}
                              onClick={() => { this.submitMessage(false, false); }}
                              className={classes.button}
                            >
                              Schedule Message
                            </Button>
                          )
                        }
                        {sendNow
                          && (
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={saving}
                              onClick={() => { this.submitMessage(true, false); }}
                              className={classes.button}
                            >
                              Send Message
                            </Button>
                          )
                        }
                      </div>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={this.checkForErrors(activeStep)}
                        onClick={this.handleNext}
                        className={classes.button}
                      >
                        Next
                    </Button>
                    )
                  }
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CreateMessage);
